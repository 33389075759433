import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Clair'

import.meta.glob(['../images/**', '../fonts/**'])

createInertiaApp({
    progress: { color: '#09459C' },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(`./App/Pages/${name}.vue`, import.meta.glob('./App/Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .mount(el)
    },
})
